import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import axios from 'axios';
import { getRegisteredUser } from '../tools/userTracking';
import { delayAction, waitFor } from '../tools/timing.js';
import { setThemeColor } from '../tools/colorScheme';
import { withNav } from '../tools/withNav';

import ContentWithToolbar from '../templates/ContentWithToolbar';
import Icon from '../components/Icon';
import Radio from '../components/Radio';

import './css/enter.css';

class Enter extends Component {
    state = {
        loading: true,
        userId: "",
        userName: "",
        categories: {},
        entries: [],
        changes: {}
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = {};
            docs.forEach(doc => {
                categories[doc.id] = doc.data();
            });
            this.setState({ categories });
        });
    };

    subscribeToEntries = async userId => {
        try {
            let unsubscribe = await Firestore.subscribe({
                collectionName: "entries",
                condition: {
                    property: "userId",
                    sign: "==",
                    value: userId
                },
                sort: "type",
                handler: docs => {
                    let entries = [];
                    docs.forEach(doc => {
                        entries.push({ ref: doc.ref, id: doc.id, ...doc.data() });
                    });
                    this.setState({ entries, loading: false });
                }
            });
            this.setState({ unsubscribe });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    // IMAGE SELECTION

    showFileSelector = () => {
        let { changes } = this.state;
        changes.image = null;
        changes.preview = null;
        this.setState({ changes });
        document.querySelector("#fileSelector").click();
    };

    handleFiles = e => {
        let { changes } = this.state,
            files = [...e.target.files],
            file = files[0];
        changes.image = file;
        this.setState({ changes });

        var reader = new window.FileReader();
		reader.onload = e => {
			changes.preview = e.target.result;
			this.setState({ changes });
		};
		reader.readAsDataURL(file);
    };

    // USER PROFILE

    openProfile = () => {
        let changes = {
            name: this.state.userName
        };
        this.setState({ changes, showProfile: true });
    };

    updateName = async () => {
        let { changes, userId, userName } = this.state;
        try {
            this.setState({ showProfile: false });
            if (changes.name !== userName) {
                await Firestore.updateDoc({
                    details: { collection: "users", docId: userId },
                    changes: { name: changes.name }
                });
            }
            this.setState({ userName: changes.name });
        } catch (e) {
            console.log(e);
        }

    };

    profileDisabled = () => {
        let { changes, userName } = this.state;
        return changes.name === "" || changes.name === userName;
    };

    // ADD NEW

    openNew = () => {
        let changes = {
            type: "PERSON",
            image: null,
            preview: null,
            description: ""
        };
        if (this.state.userName === "") changes.name = "";
        this.setState({ changes, showNew: true });
    };

    addDisabled = () => {
        let { changes } = this.state;
        if (changes.image === null) return true;
        if (changes?.name === "" ?? false) return true;
        if (changes.description === "") return true;
        return false;
    };

    addNewEntry = async () => {
        let { userId, changes } = this.state;
        this.setState({ showNew: false });
        try {
            let docRef = await Firestore.addDoc("entries", {
                type: changes.type,
                description: changes.description,
                userId: userId
            });
            this.openShare(docRef.id, changes.type);
            let path = `images/${userId}/${changes.image.name}`,
                url = await Firestore.uploadPhoto(path, changes.image, progress => {
                    progress = progress === 0 ? 1 : progress >= 100 ? null : Math.round(progress);
                    Firestore.updateDoc({
                        docRef: docRef,
                        changes: { progress: progress }
                    }).catch(err => console.log(err));
                });

            let requests = [ Firestore.updateDoc({ docRef, changes: { selfie: url } }) ];
            if (changes.name) {
                this.setState({ userName: changes.name });
                requests.push(Firestore.updateDoc({
                    details: { collection: "users", docId: userId },
                    changes: { name: changes.name }
                }));
            }
            await Promise.all(requests);
        } catch (e) {
            console.log(e);
        }
    };

    // SHARE

    openShare = (entryId, entryType) => {
        let changes = {
            processing: true,
            entryId: entryId,
            frame: entryType === "PERSON"
                ? "creative"
                : "pet"
        };
        this.setState({
            changes,
            showShare: true,
            showEdit: false,
            showNew: false
        });
        this.updateShareData(entryId);
    };

    getPreview = (entryId, category) => {
        let entry = this.state.entries.find(entry => entry.id === entryId),
            image = entry[category];
        return (image === undefined || image === null) ? undefined : encodeURIComponent(image);
    };

    updateShareData = async entryId => {
        let entry = this.state.entries.find(entry => entry.id === entryId);
        let shareLink = `https://hauntingontexas.jesseandjosh.com/vote`;
            shareLink += `?title=${encodeURIComponent(`Vote for ${entry?.description ?? "Me"}`)}`;
            shareLink += `&redirect=${encodeURIComponent('https://hauntingontexas.jesseandjosh.com/Contest/Vote')}`;
        let shareData = [];

        let categories = entry.type === "PET" ? [ "pet" ] : [ "creative", "scary", "sexy" ];

        try {
            for (let category of categories) {
                let image = undefined, timeout = 30000;
                while (image === undefined && timeout > 1) {
                    timeout -= 500;
                    await waitFor(500);
                    image = this.getPreview(entryId, category);
                }
                if (image === undefined) image = encodeURIComponent('https://hauntingontexas.jesseandjosh.com/banner.jpg');
                shareData[category] = { link: `${shareLink}&image=${image}`, image: decodeURIComponent(image) };
            }
            this.setState({ changes: { ...this.state.changes, processing: false, shareData } });
        } catch (e) {
            console.log(e);
        }
    };

    share = entry => {
        let { changes, categories } = this.state;

        let share = changes.shareData[changes.frame];

        if (navigator.share) {
            navigator.share({
                title: `Vote for ${entry.description}`,
                text: `Please vote my costume for ${categories[changes.frame].description}`,
                url: share.link
            }).then(() => console.log('Successful share!')).catch(console.log);
        } else {
            window.open(share.image, "_blank");
        }
    }

    // EDIT

    openEdit = entry => {
        let changes = {
            entry: entry,
            type: entry.type,
            image: null,
            preview: null,
            selfie: entry.selfie,
            description: entry.description
        };
        this.setState({ changes, showEdit: true });
    };

    editDisabled = () => {
        let { changes } = this.state,
            entry = changes.entry;
        if (changes.type !== entry.type) return false;
        if (changes.image !== null) return false;
        if (changes.description !== "" && changes.description !== entry.description) return false;
        return true;
    };

    editEntry = async () => {
        let { userId, changes } = this.state,
            entry = changes.entry, data = {};
        this.setState({ showEdit: false });
        try {
            if (changes.type !== entry.type) data.type = changes.type;
            if (changes.image) {
                let path = `images/${userId}/${changes.image.name}`;
                data.selfie = await Firestore.uploadPhoto(path, changes.image, progress => {
                    progress = progress === 0 ? 1 : progress >= 100 ? null : Math.round(progress);
                    Firestore.updateDoc({
                        docRef: entry.ref,
                        changes: { progress: progress }
                    }).catch(err => console.log(err));
                });
            }
            if (changes.description !== entry.description) data.description = changes.description;
            if (Object.keys(changes).length > 0) {
                let requests = [ Firestore.updateDoc({ docRef: entry.ref, changes: data }) ];
                if (data.selfie) requests.push(Firestore.removePhoto(entry.selfie));
                return await Promise.all(requests);
            }
            return null;
        } catch (e) {
            console.log(e);
        }
    };

    deleteEntry = async () => {
        let { changes } = this.state,
            entry = changes.entry;
        this.setState({ showEdit: false });
        try {
            let requests = [
                Firestore.removePhoto(entry.selfie),
                Firestore.deleteDoc({ docRef: entry.ref }),
                Firestore.deleteDocs({
                    collection: "votes",
                    condition: { property: "entryId", sign: "==", value: entry.id }
                })
            ];
            let results = await Promise.all(requests);
            return null;
        } catch (e) {
            console.log(e);
        }
    };

    handleEdit = e => {
        let { changes } = this.state,
            key = e.target.name,
            value = e.target.value;
        changes[key] = value;
        this.setState({ changes });
    };

    selectType = type => {
        let { changes } = this.state;
        changes.type = type;
        this.setState({ changes });
    };

    componentDidMount() {
        window.setTitle("Enter the Costume Contest | Haunting on Texas");
        setThemeColor(40, 35, 42);

        this.getCategories();
        let userId = getRegisteredUser();
        this.setState({ userId });
        this.subscribeToEntries(userId);

        Firestore.getDoc({ details: { collection: "users", docId: userId } })
            .then(user => this.setState({ userName: user.data().name }))
            .catch(err => console.log(err));
    }

    componentWillUnmount() {
        if (this.state.unsubscribe) this.state.unsubscribe();
    }

    render() {
        let { changes, loading } = this.state,
            selectedEntry = this.state.entries.find(entry => (entry.id === changes?.entryId ?? ""));
        return (<>
            <ContentWithToolbar id="entry">
                <h2>My Costumes<Icon icon="user-circle" onClick={this.openProfile} /></h2>
                <input id="fileSelector" type="file" accept="image/*" onChange={this.handleFiles} />
                <section>
                    {!loading && this.state.entries.length > 0 && <ul>
                        {this.state.entries.map(entry => (
                            <li onClick={() => this.openEdit(entry)}>
                                {entry.selfie
                                    ? <img src={entry.selfie} />
                                    : <Icon icon={entry.type === "PERSON" ? "user" : "cat"} />
                                }
                                <b>{entry.description}</b>
                                {entry.progress && <div className="progress">
                                    <div></div>
                                    <b>{entry.progress}%</b>
                                </div>}
                            </li>
                        ))}
                    </ul>}
                    {!loading && this.state.entries.length === 0 && <div className="message">
                        <p><b>No Costumes Yet!</b></p>
                        <p>Click the add button below to enter the contest</p>
                    </div>}
                    {loading && <div className="progress">
                        <div></div>
                        <b>Loading...</b>
                    </div>}
                </section>
                <Icon id="new" icon="plus" onClick={this.openNew} />
            </ContentWithToolbar>
            {this.state.showNew && <div className="dialogContainer">
                <div className="dialog">
                    <h1>New Costume</h1>
                    <div id="content">
                        <div id="type">
                            <div className={changes.type === "PERSON" ? "selected" : ""} onClick={() => this.selectType("PERSON")}>
                                <Icon icon="user" />Person
                            </div>
                            <div className={changes.type === "PET" ? "selected" : ""} onClick={() => this.selectType("PET")}>
                                <Icon icon="cat" />Pet
                            </div>
                        </div>
                        {changes.preview
                            ? <img id="preview" src={changes.preview} onClick={this.showFileSelector} />
                            : <div id="imagePrompt" onClick={this.showFileSelector}>
                                <Icon icon="image" />
                                <b>Upload a Selfie</b>
                            </div>
                        }
                        {this.state.userName === "" && <>
                            <label>What's your name?</label>
                            <input type="text" name="name" placeholder="Enter your name..." onInput={this.handleEdit} />
                        </>}
                        <label>What's your costume?</label>
                        <input type="text" name="description" placeholder="Describe your costume..." onInput={this.handleEdit} />
                    </div>
                    <div id="footer">
                        <button onClick={() => this.setState({ showNew: false })}>Cancel</button>
                        <button onClick={this.addNewEntry} disabled={this.addDisabled()}>Submit</button>
                    </div>
                </div>
            </div>}
            {this.state.showEdit && <div className="dialogContainer">
                <div className="dialog">
                    <h1>Edit Costume<Icon icon="share-square" onClick={() => this.openShare(changes.entry.id, changes.type)} /></h1>
                    <div id="content">
                        <div id="type">
                            <div className={changes.type === "PERSON" ? "selected" : ""} onClick={() => this.selectType("PERSON")}>
                                <Icon icon="user" />Person
                            </div>
                            <div className={changes.type === "PET" ? "selected" : ""} onClick={() => this.selectType("PET")}>
                                <Icon icon="cat" />Pet
                            </div>
                        </div>
                        <img id="preview" src={changes.preview ?? changes.selfie} onClick={this.showFileSelector} />
                        <label>What's your costume?</label>
                        <input type="text" name="description" placeholder="Describe your costume..." value={changes.description} onInput={this.handleEdit} />
                        <button className="delete" onClick={this.deleteEntry}>Delete This Entry</button>
                    </div>
                    <div id="footer">
                        <button onClick={() => this.setState({ showEdit: false })}>Cancel</button>
                        <button onClick={this.editEntry} disabled={this.editDisabled()}>Save</button>
                    </div>
                </div>
            </div>}
            {this.state.showShare && <div className="dialogContainer">
                <div className="dialog">
                    <div id="content">
                        <h1>Share Your Costume!</h1>
                        <div id="entry" className={changes.frame}>
                            {selectedEntry?.selfie ?? false
                                ? <img src={selectedEntry?.selfie} />
                                : <Icon icon={(selectedEntry?.type ?? "PERSON") === "PERSON" ? "user" : "cat"} />
                            }
                            <h3>
                                <span>{selectedEntry?.description
                                    ? `Vote for ${selectedEntry.description}`
                                    : "Loading..."
                                }</span>
                                {selectedEntry?.description
                                    ? <b>{this.state.categories[changes.frame]?.description}</b>
                                    : ""
                                }
                            </h3>
                            {selectedEntry?.progress && <div className="progress">
                                <div></div>
                                <b>{selectedEntry?.progress}%</b>
                            </div>}
                        </div>
                        {changes.frame !== "pet"
                            ? <p>Choose a category you hope to win below and {navigator.share ? "share a custom link to vote for your costume" : "download a shareable banner for social media"}.</p>
                            : <p>{navigator.share ? "Share a custom link to vote for your costume" : "Download a shareable banner for social media"}.</p>
                        }
                        {changes.frame !== "pet" && <Radio vertical name="category" selected={changes.frame} options={[
                            { label: "Most Creative", value: "creative" },
                            { label: "Scariest", value: "scary" },
                            { label: "Sexiest", value: "sexy" }
                        ]} onChange={value => {
                            let { changes } = this.state;
                            changes.frame = value;
                            this.setState({ changes });
                        }} />}
                    </div>
                    <div id="footer">
                        <button onClick={() => this.setState({ showShare: false })}>Close</button>
                        <button onClick={() => this.share(selectedEntry)} disabled={changes?.processing || !changes?.shareData}>
                            {changes.processing || !changes?.shareData
                                ? "Processing..."
                                : navigator.share ? "Share" : "Download"
                            }
                        </button>
                    </div>
                </div>
            </div>}
            {this.state.showProfile && <div className="dialogContainer">
                <div className="dialog noGrow">
                    <h1>My Profile</h1>
                    <div id="content">
                        <label>Name</label>
                        <input type="text" name="name" placeholder="Enter your name..." value={changes.name} onInput={this.handleEdit} />
                    </div>
                    <div id="footer">
                        <button onClick={() => this.setState({ showProfile: false })}>Close</button>
                        <button onClick={this.updateName} disabled={this.profileDisabled()}>Save</button>
                    </div>
                </div>
            </div>}
        </>);
    }
}

export default withNav(Enter);
