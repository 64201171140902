import { firestore, storage } from './firebase.js';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getRegisteredUser, setAdminToken } from './userTracking.js';
import { collection, query, where, orderBy, doc, addDoc, getDoc, getDocs, setDoc, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, uploadString, getDownloadURL, deleteObject } from "firebase/storage";

let authenticated = false;

const signIn = async () => {
    try {
        if (authenticated) return "OK";
        let userId = getRegisteredUser();
        if (userId === null) throw "User Not Found";
        let response = await axios.get(`https://hauntingontexas.jesseandjosh.com/login?userId=${userId}`);
        let credential = await signInWithCustomToken(getAuth(), response.data);
        authenticated = true;
        return "OK";
    } catch (e) {
        console.log(e);
        return e;
    }
};

export const adminSignIn = async credentials => {
    try {
        if ((credentials?.username === "" ?? true) || (credentials?.password === "" ?? true)) throw "Missing credentials";
        let response = await axios.get(`https://hauntingontexas.jesseandjosh.com/adminLogin`, { params: credentials });
        let token = response.data;
        setAdminToken(token);
        return token;
    } catch (e) {
        console.log(e);
        return e;
    }
};

export const addDocument = async (collectionName, data, id) => {
    try {
        await signIn();
        if (id !== undefined) {
            let docRef = doc(firestore, collectionName, id);
            await setDoc(docRef, data);
            return docRef;
        } else {
            let docRef = await addDoc(collection(firestore, collectionName), data);
            return docRef;
        }
    } catch (e) {
        throw e;
    }
};

export const getDocument = async ({ docRef, details }) => {
    try {
        await signIn();
        docRef = docRef !== undefined ? docRef : doc(firestore, details.collection, details.docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap;
        } else {
            throw "Document Doesn't Exist!";
        }
    } catch (e) {
        throw e;
    }
};

export const getDocuments = async (collectionName, condition) => {
    try {
        await signIn();
        let q = condition !== undefined
            ? query(collection(firestore, collectionName), where(condition.property, condition.sign, condition.value))
            : collection(firestore, collectionName);

        const querySnapshot = await getDocs(q);
        return querySnapshot;
    } catch (e) {
        throw e;
    }
};

export const subscribe = async ({ collectionName, condition, sort, handler }) => {
    try {
        await signIn();
        let col = collection(firestore, collectionName);
        let q = condition !== undefined
            ? query(col, where(condition.property, condition.sign, condition.value))
            : col;

        return onSnapshot(q, handler);
    } catch (e) {
        throw e;
    }
};

export const updateDocument = async ({ docRef, details, changes }) => {
    try {
        await signIn();
        docRef = docRef !== undefined ? docRef : doc(firestore, details.collection, details.docId);
        let result = await updateDoc(docRef, changes);
        return result;
    } catch (e) {
        throw e;
    }
};

export const deleteDocument = async ({ docRef, details }) => {
    try {
        await signIn();
        docRef = docRef !== undefined ? docRef : doc(firestore, details.collection, details.docId);
        await deleteDoc(docRef);
        return "OK";
    } catch (e) {
        throw e;
    }
};

export const deleteDocuments = async ({ docRefs, collection, condition }) => {
    try {
        await signIn();
        let requests = [];
        if (docRefs) {
            requests = docRefs.map(docRef => deleteDoc(docRef));
        } else if (collection && condition) {
            if ((condition.property === undefined) ||
                (condition.sign === undefined) ||
                (condition.value === undefined)
            ) {
                throw "Invalid condition provided";
            }
            let docs = await getDocuments(collection, condition);
            docs.forEach(doc => requests.push(deleteDoc(doc.ref)));
        } else {
            throw "Missing params";
        }
        await Promise.all(requests);
        return "OK";
    } catch (e) {
        throw e;
    }
};

export const getPhotoUrl = path => {
    return new Promise((resolve, reject) => {
        signIn().then(result => {
            const fileRef = ref(storage, path);
            getDownloadURL(fileRef)
                .then(resolve)
                .catch(reject);
        }).catch(reject);
    });
};

export const uploadBase64 = async (path, base64String, type = "image/png") => {
    try {
        await signIn();
        let fileRef = ref(storage, path);
        let snapshot = await uploadString(fileRef, base64String, 'base64', { contentType: type });
        let downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (e) {
        throw e;
    }
};

export const uploadPhoto = (path, file, onProgress) => {
    return new Promise((resolve, reject) => {
        signIn().then(result => {
            const fileRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on('state_changed', snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) onProgress(progress);
            }, err => reject(err), () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    resolve(downloadURL);
                })
            });
        }).catch(reject);
    });
};

export const removePhoto = url => {
    return new Promise((resolve, reject) => {
        signIn().then(result => {
            let path = decodeURIComponent(url).split('?')[0];
            path = "images" + path.split('images')[1];

            let fileRef = ref(storage, path);
            deleteObject(fileRef)
                .then(resolve).catch(reject);
        }).catch(reject);
    });
};

let functions = {
    addDoc: addDocument,
    getDoc: getDocument,
    getDocs: getDocuments,
    subscribe: subscribe,
    updateDoc: updateDocument,
    deleteDoc: deleteDocument,
    deleteDocs: deleteDocuments,
    getPhotoUrl: getPhotoUrl,
    uploadPhoto: uploadPhoto,
    uploadBase64: uploadBase64,
    removePhoto: removePhoto
};

export default functions;
