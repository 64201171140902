import { withRouter } from 'react-router-dom';

var history = [];
var future = [];

const addSpaces = str => {
	if (str === undefined) return undefined;
	let matches = str.match(/[A-Z]/g);
	if (matches && matches.length > 1) {
		for (var i = 1; i < matches.length; i++) {
			 var index = str.indexOf(matches[i]);
			 str = str.splice(index, 0, " ");
		}
	}
	return str;
};

const pathToTitle = pathname => {
	var path = pathname.split("/");
	var pageTitle = path[1] ?? "Splash Screen";
	pageTitle = pageTitle.split("?")[0];
	return addSpaces(pageTitle);
};

export function withNav(WrappedComponent) {
	const WrappedWithHistory = class extends WrappedComponent {
		getCurrentPage = () => {
			var location = this.props.location ? this.props.location.pathname : "";
			return pathToTitle(location);
		};

		getPrevPage = () => {
			if (this.canGoBack()) {
				var lastPage = history.length - 1;
				return history[lastPage].name;
			} else {
				return undefined;
			}
		};

		canGoBack = () => { return history.length > 0 };

		canGoForward = () => { return future.length > 1 };

		goBack = leavePage => {
			if (this.canGoBack()) {
				let page = history.pop();
				future.push(page);
				this.props.history.goBack();
			}
		};

		goForward = leavePage => {
			if (this.canGoForward()) {
				let page = future.pop();
				history.push(page);
				this.props.history.goForward();
			}
		};

		goTo = (path, leavePage) => {
			var page;
			if (future.length === 0) {
				var currentPage = this.props.location.pathname;
				page = { name: pathToTitle(currentPage), path: currentPage};
			} else {
				page = future.pop();
			}

			history.push(page);
			future = [{ name: pathToTitle(path), path: path }];
			this.props.history.push(path);
		};

		redirectTo = path => { this.props.history.replace(path) };

		render() {
			return super.render();
		}
	}

	return withRouter(WrappedWithHistory);
}
