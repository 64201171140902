import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { registerUser, userHasEntered, userHasVoted } from './tools/userTracking.js';

import Details from './pages/Details';
import Enter from './pages/Enter';
import Vote from './pages/Vote';
import Sent from './pages/Sent';
import Results from './pages/Results';
import Admin from './pages/Admin';
import Banner from './partials/Banner';
import NotFound from './pages/NotFound';

class App extends Component {
    state = {
        userId: registerUser()
    };

	componentDidMount() {
        window.setImage("https://hauntingontexas.jesseandjosh.com/banner.jpg");
        window.setTitle("Haunting on Texas");
        window.setDescription(null);
	}

  	render() {
	  	return (
		  	<Switch>
		  		<Route exact path="/" component={Details} />
                <Route exact path="/Contest" render={props => <Redirect to="/Contest/Enter" />} />
                <Route exact path="/Contest/Enter" component={Enter} />
                <Route exact path="/Contest/Vote" component={Vote} />
                <Route exact path="/Contest/Vote/Sent" component={Sent} />
                <Route exact path="/Contest/Results" component={Results} />
                <Route exact path="/Admin" component={Admin} />
                <Route exact path="/Banner/:entryId" render={props => <Banner {...props} />} />
                <Route exact path="/Banner" component={Banner} />
				<Route path="/:page" render={props => {
                    return <NotFound {...props} />;
				}} />
		  	</Switch>
	  	);
	}
}

export default App;
