import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import { getRegisteredUser } from '../tools/userTracking';
import { setThemeColor } from '../tools/colorScheme';
import { withNav } from '../tools/withNav';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Icon from '../components/Icon';

import './css/vote.css';

class Vote extends Component {
    state = {
        loading: true,
        userId: "",
        selectedCat: 0,
        categories: [],
        entries: [],
        votes: []
    };

    subscribeToStatus = async () => {
        try {
            let unsubscribeStatus = await Firestore.subscribe({
                collectionName: "admin",
                handler: docs => {
                    docs.forEach(doc => {
                        if (doc.id === "settings") {
                            let votingOpen = doc.data().votingOpen;
                            if (!votingOpen) {
                                this.goTo("/Contest/Results");
                            }
                        }
                    });
                }
            });
            this.setState({ unsubscribeStatus });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = [];
            docs.forEach(doc => categories.push({ id: doc.id, ...doc.data() }));
            this.setState({ categories: categories.sort((a, b) => {
                if (a.type === b.type) return 0;
                return a.type < b.type ? -1 : 1;
            }) });
            this.setThemeBar(categories[0].id);
        });
    };

    subscribeToEntries = async () => {
        try {
            let unsubscribeEntries = await Firestore.subscribe({
                collectionName: "entries",
                sort: "type",
                handler: docs => {
                    let entries = [];
                    docs.forEach(doc => {
                        entries.push({ id: doc.id, ...doc.data() });
                    });
                    this.setState({ entries, loading: false });
                }
            });
            this.setState({ unsubscribeEntries });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    subscribeToVotes = async userId => {
         try {
             let unsubscribeVotes = await Firestore.subscribe({
                collectionName: "votes",
                condition: {
                    property: "userId",
                    sign: "==",
                    value: userId
                },
                sort: "categoryId",
                handler: docs => {
                    let votes = [];
                    docs.forEach(doc => {
                        votes.push({ ref: doc.ref, ...doc.data() });
                    });
                    this.setState({ votes });
                }
            });
            this.setState({ unsubscribeVotes });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    entryClassName = entryId => {
        let { votes, selectedCat, categories } = this.state,
            categoryId = categories[selectedCat].id;
        return votes.filter(vote => {
            return vote.categoryId === categoryId && vote.entryId === entryId
        }).length > 0 ? "selected" : "";
    };

    chooseEntry = entryId => {
        let { userId, votes, selectedCat, categories } = this.state,
            categoryId = categories[selectedCat].id;
        let prevVotes = votes.filter(v => v.categoryId === categoryId);
        if (prevVotes.length === 0) {
            votes.push({ categoryId, entryId, userId });
        } else {
            votes = votes.map(v => {
                if (v.categoryId === categoryId) {
                    v.entryId = entryId;
                }
                return v;
            });
        }
        this.setState({ votes });
    };

    setThemeBar = categoryId => {
        switch (categoryId) {
            case "creative":
                setThemeColor(39, 64, 81);
                break;
            case "scary":
                setThemeColor(58, 24, 30);
                break;
            case "sexy":
                setThemeColor(59, 25, 70);
                break;
            case "pet":
                setThemeColor(54, 109, 113);
                break;
            default:
                setThemeColor(24, 19, 26);
        }
    };

    prevCategory = () => {
        let { categories, selectedCat } = this.state;
        selectedCat -= 1;
        this.setState({ selectedCat });
        this.setThemeBar(categories[selectedCat].id);
        window.scrollTo(0, 0);
    };

    nextCategory = () => {
        let { selectedCat, categories } = this.state;
        selectedCat += 1;
        if (selectedCat >= categories.length) {
            this.submitVotes();
        } else {
            this.setState({ selectedCat });
        }
        this.setThemeBar(categories[selectedCat]?.id ?? "default");
        window.scrollTo(0, 0);
    };

    checkDisabled = () => {
        let { votes, selectedCat, categories } = this.state,
            categoryId = categories[selectedCat]?.id ?? "";
        let votesFound = votes.filter(vote => vote.categoryId === categoryId);
        return votesFound.length === 0;
    };

    submitVotes = () => {
        let { votes } = this.state,
            requests = [];
        votes.forEach(vote => {
            if (vote.ref === undefined) {
                requests.push(Firestore.addDoc("votes", vote));
            } else {
                requests.push(Firestore.updateDoc({
                    docRef: vote.ref,
                    changes: {
                        categoryId: vote.categoryId,
                        entryId: vote.entryId
                    }
                }));
            }
        });
        Promise.all(requests)
            .then(results => this.goTo("/Contest/Vote/Sent"))
            .catch(err => console.log(err));
    };

    componentDidMount() {
        window.setTitle("Submit Your Votes | Haunting on Texas");
        setThemeColor(24, 19, 26);
        this.getCategories();
        let userId = getRegisteredUser();
        this.setState({ userId });
        this.subscribeToStatus();
        this.subscribeToEntries();
        this.subscribeToVotes(userId);
    }

    componentWillUnmount() {
        if (this.state.unsubscribeStatus) this.state.unsubscribeStatus();
        if (this.state.unsubscribeEntries) this.state.unsubscribeEntries();
        if (this.state.unsubscribeVotes) this.state.unsubscribeVotes();
    }

    render() {
        let curCat = this.state.categories[this.state.selectedCat],
            { loading } = this.state,
            filteredEntries = this.state.entries.filter(entry => {
                return entry.type === curCat?.type ?? false;
            });
        return (
            <ContentWithToolbar id="vote">
                <h2 className={curCat?.id ?? ""}>{curCat?.description ?? "Vote"}</h2>
                {!loading && filteredEntries.length > 0 && <p>Please pick your favorite costume for this category and continue</p>}
                <section>
                    {!loading && filteredEntries.length > 0 && <ul>
                        {filteredEntries.map(entry => (
                            <li className={this.entryClassName(entry.id)} onClick={() => this.chooseEntry(entry.id)}>
                                <img src={entry.selfie} />
                                <b>{entry.description}</b>
                            </li>
                        ))}
                    </ul>}
                    {!loading && filteredEntries.length === 0 &&
                        <p className="largeMessage"><b>No costumes have been entered yet</b>Check back later</p>
                    }
                    {loading && <div className="progress">
                        <div></div>
                        <b>Loading...</b>
                    </div>}
                </section>
                <div id="footer">
                    {this.state.selectedCat > 0 && <button onClick={this.prevCategory}><Icon icon="arrow-left" /></button>}
                    <button onClick={this.nextCategory} disabled={this.checkDisabled()}>
                        {this.state.categories[this.state.selectedCat + 1]?.description ?? "Submit"}
                        <Icon className="right" icon="arrow-right" />
                    </button>
                </div>
            </ContentWithToolbar>
        );
    }
}

export default withNav(Vote);
