import config from './config.js';
import Firestore from './firestore_tools';
import { v4 as uuid } from 'uuid';

export const registerUser = () => {
    let userId = localStorage.getItem(config.UserIdKey);
    if (userId === null) {
        userId = uuid();
        localStorage.setItem(config.UserIdKey, userId);
        Firestore.addDoc("users", { name: "" }, userId).catch(err => console.log(err));
    }
    return userId;
};

export const getRegisteredUser = () => {
    return localStorage.getItem(config.UserIdKey);
};

export const getAdminToken = () => {
    return localStorage.getItem(config.AdminKey);
};

export const setAdminToken = token => {
    localStorage.setItem(config.AdminKey, token);
};
