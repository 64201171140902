import React, { Component } from 'react';
import Firestore, { adminSignIn } from '../tools/firestore_tools';
import { setThemeColor } from '../tools/colorScheme';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { getAdminToken, setAdminToken } from '../tools/userTracking';
import { withNav } from '../tools/withNav';

import ContentWithToolbar from '../templates/ContentWithToolbar';
import Icon from '../components/Icon';

import './css/admin.css';

class Admin extends Component {
    state = {
        authorized: false,
        votingOpen: true,
        selectedCat: "creative",
        categories: {},
        entries: [],
        users: {},
        votes: [],
        changes: {
            username: "",
            password: ""
        }
    };

    subscribeToStatus = async () => {
        try {
            let unsubscribeStatus = await Firestore.subscribe({
                collectionName: "admin",
                handler: docs => {
                    docs.forEach(doc => {
                        if (doc.id === "settings") {
                            let votingOpen = doc.data().votingOpen;
                            this.setState({ votingOpen });
                        }
                    });
                }
            });
            this.setState({ unsubscribeStatus });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = {};
            docs.forEach(doc => {
                categories[doc.id] = doc.data();
            });
            this.setState({ categories });
        });
    };

    subscribeToEntries = async () => {
        try {
            let unsubscribeEntries = await Firestore.subscribe({
                collectionName: "entries",
                sort: "type",
                handler: docs => {
                    let entries = [];
                    docs.forEach(doc => {
                        entries.push({ id: doc.id, ref: doc.ref, ...doc.data() });
                    });
                    this.setState({ entries });
                }
            });
            this.setState({ unsubscribeEntries });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    subscribeToUsers = async () => {
        try {
            let unsubscribeUsers = await Firestore.subscribe({
                collectionName: "users",
                handler: docs => {
                    let users = {};
                    docs.forEach(doc => {
                        users[doc.id] = doc.data();
                    });
                    this.setState({ users });
                }
            });
            this.setState({ unsubscribeUsers });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    subscribeToVotes = async () => {
        try {
            let unsubscribeVotes = await Firestore.subscribe({
                collectionName: "votes",
                handler: docs => {
                    let votes = [];
                    docs.forEach(doc => {
                        votes.push(doc.data());
                    });
                    this.setState({ votes });
                }
            });
            this.setState({ unsubscribeVotes });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    getVoteCount = entryId => {
        let { votes, selectedCat } = this.state;
        if (votes.length === 0) return "No";
        let total = votes.filter(vote => {
            return vote.entryId === entryId && vote.categoryId === selectedCat;
        }).length;
        return total;
    };

    toggleStatus = async () => {
        let { votingOpen } = this.state;
        try {
            await Firestore.updateDoc({
                details: { collection: "admin", docId: "settings" },
                changes: { votingOpen: !votingOpen }
            });
            return;
        } catch (e) {
            console.log(e);
        }
    };

    // EDIT

    openEdit = entry => {
        let changes = {
            entry: entry,
            type: entry.type,
            image: null,
            preview: null,
            selfie: entry.selfie,
            description: entry.description
        };
        this.setState({ changes, showEdit: true });
    };

    editDisabled = () => {
        let { changes } = this.state,
            entry = changes.entry;
        if (changes.type !== entry.type) return false;
        if (changes.image !== null) return false;
        if (changes.description !== "" && changes.description !== entry.description) return false;
        return true;
    };

    editEntry = async () => {
        let { userId, changes } = this.state,
            entry = changes.entry, data = {};
        this.setState({ showEdit: false });
        try {
            if (changes.type !== entry.type) data.type = changes.type;
            if (changes.image) {
                let path = `images/${userId}/${changes.image.name}`;
                data.selfie = await Firestore.uploadPhoto(path, changes.image, progress => {
                    progress = progress === 0 ? 1 : progress >= 100 ? null : Math.round(progress);
                    Firestore.updateDoc({
                        docRef: entry.ref,
                        changes: { progress: progress }
                    }).catch(err => console.log(err));
                });
            }
            if (changes.description !== entry.description) data.description = changes.description;
            if (Object.keys(changes).length > 0) {
                let requests = [ Firestore.updateDoc({ docRef: entry.ref, changes: data }) ];
                if (data.selfie) requests.push(Firestore.removePhoto(entry.selfie));
                return await Promise.all(requests);
            }
            return null;
        } catch (e) {
            console.log(e);
        }
    };

    deleteEntry = async () => {
        let { changes } = this.state,
            entry = changes.entry;
        this.setState({ showEdit: false });
        try {
            let requests = [
                Firestore.removePhoto(entry.selfie),
                Firestore.deleteDoc({ docRef: entry.ref }),
                Firestore.deleteDocs({
                    collection: "votes",
                    condition: { property: "entryId", sign: "==", value: entry.id }
                })
            ];
            let results = await Promise.all(requests);
            return null;
        } catch (e) {
            console.log(e);
        }
    };

    // IMAGE SELECTION

    showFileSelector = () => {
        let { changes } = this.state;
        changes.image = null;
        changes.preview = null;
        this.setState({ changes });
        document.querySelector("#fileSelector").click();
    };

    handleFiles = e => {
        let { changes } = this.state,
            files = [...e.target.files],
            file = files[0];
        changes.image = file;
        this.setState({ changes });

        var reader = new window.FileReader();
		reader.onload = e => {
			changes.preview = e.target.result;
			this.setState({ changes });
		};
		reader.readAsDataURL(file);
    };

    handleEdit = e => {
        let { changes } = this.state,
            key = e.target.name,
            value = e.target.value;
        changes[key] = value;
        this.setState({ changes });
    };

    selectType = type => {
        let { changes } = this.state;
        changes.type = type;
        this.setState({ changes });
    };

    checkAuth = () => {
        let token = getAdminToken();
        if (token === null) this.setState({ authorized: false });
        let decoded = jwt.decode(token);
        let exp = decoded?.exp ? moment.unix(decoded.exp) : moment().subtract(1, 'day'),
            now = moment();
        let tokenValid = now <= exp;
        this.setState({ authorized: tokenValid });
        if (!tokenValid) setAdminToken(null);
    };

    signIn = () => {
        let credentials = this.state.changes;
        adminSignIn(credentials).then(token => {
            this.checkAuth();
        }).catch(console.log);
    };

    componentDidMount() {
        this.checkAuth();
        window.setTitle("Control Panel | Haunting on Texas");
        setThemeColor(58, 24, 30);
        this.subscribeToStatus();
        this.getCategories();
        this.subscribeToEntries();
        this.subscribeToUsers();
        this.subscribeToVotes();
    }

    componentWillUnmount() {
        if (this.state.unsubscribeStatus) this.state.unsubscribeStatus();
        if (this.state.unsubscribeEntries) this.state.unsubscribeEntries();
        if (this.state.unsubscribeUsers) this.state.unsubscribeUsers();
        if (this.state.unsubscribeVotes) this.state.unsubscribeVotes();
    }

    render() {
        let changes = this.state.changes,
            filteredEntries = this.state.entries.filter(entry => {
                let { categories, selectedCat } = this.state;
                return entry.type === categories?.[selectedCat]?.type ?? false;
            }).sort((a, b) => {
                let votesForA = this.getVoteCount(a.id),
                    votesForB = this.getVoteCount(b.id);
                if (votesForA === votesForB) {
                    if (a.description === b.description) return 0;
                    return a.description < b.description ? -1 : 0;
                }
                return votesForA > votesForB ? -1 : 1;
            });
        return (<><ContentWithToolbar id="admin">
            <input id="fileSelector" type="file" accept="image/*" onChange={this.handleFiles} />
            <h2>Control Panel<div className="status">
                <span>Voting<br />Status</span>
                <div onClick={this.toggleStatus} className={this.state.votingOpen ? "toggle on" : "toggle"}>
                    <span></span>
                </div>
            </div></h2>
            <section>
                <h1>Contest Entries</h1>
                {this.state.authorized && <ul id="categories">
                    {Object.keys(this.state.categories).sort((a, b) => {
                        let categories = this.state.categories;
                        if (categories[a].type === categories[b].type) return 0;
                        return categories[a].type < categories[b].type ? -1 : 1;
                    }).map(category => (
                        <li className={category === this.state.selectedCat ? "selected" : ""} onClick={() => this.setState({ selectedCat: category })}>{category}</li>
                    ))}
                </ul>}
                {this.state.authorized && filteredEntries.map(entry => (
                    <div className="entry" onClick={() => this.openEdit(entry)}>
                        <img src={entry.selfie} />
                        <div>
                            <b>{entry.description}</b>
                            {this.state.users?.[entry.userId]?.name ?? "Loading name..."}
                        </div>
                        <div id="votes">
                            {this.getVoteCount(entry.id)} Votes
                        </div>
                    </div>
                ))}
                {this.state.authorized && filteredEntries.length === 0 && <>
                    <p>No Entries Found</p>
                </>}
            </section>
        </ContentWithToolbar>
        {this.state.showEdit && <div className="dialogContainer">
            <div className="dialog">
                <h1>Edit Costume</h1>
                <div id="content">
                    <div id="type">
                        <div className={changes.type === "PERSON" ? "selected" : ""} onClick={() => this.selectType("PERSON")}>
                            <Icon icon="user" />Person
                        </div>
                        <div className={changes.type === "PET" ? "selected" : ""} onClick={() => this.selectType("PET")}>
                            <Icon icon="cat" />Pet
                        </div>
                    </div>
                    <img id="preview" src={changes.preview ?? changes.selfie} onClick={this.showFileSelector} />
                    <label>What's your costume?</label>
                    <input type="text" name="description" placeholder="Describe your costume..." value={changes.description} onInput={this.handleEdit} />
                    <button className="delete" onClick={this.deleteEntry}>Delete This Entry</button>
                </div>
                <div id="footer">
                    <button onClick={() => this.setState({ showEdit: false })}>Cancel</button>
                    <button onClick={this.editEntry} disabled={this.editDisabled()}>Save</button>
                </div>
            </div>
        </div>}
        {!this.state.authorized && <div className="dialogContainer">
            <div className="dialog noGrow">
                <h1>Sign In</h1>
                <div id="content">
                    <label>Username</label>
                    <input type="text" name="username" placeholder="Username" value={changes.username} onInput={this.handleEdit} />
                    <label>Password</label>
                    <input type="password" name="password" placeholder="Password" value={changes.password} onInput={this.handleEdit} />
                </div>
                <div id="footer">
                    <button onClick={() => this.goTo("/Contest")}>Back to My Costumes</button>
                    <button onClick={this.signIn} disabled={changes.username === "" || changes.password === "" || changes.processing}>{changes.processing ? "processing..." : "Sign In"}</button>
                </div>
            </div>
        </div>}
        </>);
    }
}

export default withNav(Admin);
