import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyOLRklE4qLS1hAIjdlf3dtTyOMBwZ598",
  authDomain: "costume-contest.firebaseapp.com",
  projectId: "costume-contest",
  storageBucket: "costume-contest.appspot.com",
  messagingSenderId: "344872101926",
  appId: "1:344872101926:web:dde0c4a7c4e94f41ebdee7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export let firestore = getFirestore();
export let storage = getStorage(app);

let features = {
    app: app,
    firestore: firestore,
    storage: storage
};

export default features;
