import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import { withNav } from '../tools/withNav';

import ContentWithToolbar from '../templates/ContentWithToolbar';
import Button from '../components/Button';
import Icon from '../components/Icon';

import "./css/results.css";

class Results extends Component {
    state = {
        votingOpen: true,
        categories: [],
        votes: {},
        entries: {}
    };

    subscribeToStatus = async () => {
        try {
            let unsubscribeStatus = await Firestore.subscribe({
                collectionName: "admin",
                handler: docs => {
                    docs.forEach(doc => {
                        if (doc.id === "settings") {
                            let votingOpen = doc.data().votingOpen;
                            this.setState({ votingOpen });
                        }
                    });
                }
            });
            this.setState({ unsubscribeStatus });
            return "OK";
        } catch (e) {
            console.log(e);
        }
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = [];
            docs.forEach(doc => categories.push({ id: doc.id, ...doc.data() }));
            this.setState({ categories: categories.sort((a, b) => {
                if (a.type === b.type) return 0;
                return a.type < b.type ? -1 : 1;
            }) });
        });
    };

    getResults = async () => {
        try {
            let docs = await Firestore.getDocs("votes");
            let votes = {}, entries = {};
            docs.forEach(doc => {
                let vote = doc.data();
                votes[vote.categoryId] = votes[vote.categoryId]
                    ? [ ...votes[vote.categoryId], vote.entryId ]
                    : [ vote.entryId ];
            });
            Object.keys(votes).forEach(categoryId => {
                let entryIds = votes[categoryId];
                let counts = entryIds.reduce((prev, cur) => {
                    prev[cur] = (prev[cur] || 0) + 1;
                    return prev;
                }, {});
                entryIds = Object.keys(counts).sort((a, b) => {
                    if (counts[a] === counts[b]) return 0;
                    return counts[a] < counts[b] ? 1 : -1;
                }).filter((entryId, i, arr) => {
                    if (i === 0) return true;
                    return counts[entryId] === counts[arr[0]];
                });
                votes[categoryId] = entryIds;
                entryIds.forEach(entryId => {
                    entries[entryId] = {};
                });
            });
            this.setState({ votes, entries });

            let results = await Promise.all(Object.keys(entries).map(entryId => {
                return Firestore.getDoc({ details: {
                    collection: "entries",
                    docId: entryId
                }});
            }));
            results.forEach(doc => { entries[doc.id] = doc.data() });
            this.setState({ entries });
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount() {
        window.setTitle("Contest Winners | Haunting on Texas");
        this.subscribeToStatus();
        this.getCategories();
        this.getResults();
    }

    componentWillUnmount() {
        if (this.state.unsubscribeStatus) this.state.unsubscribeStatus();
    }

    render() {
        let votingOpen = this.state.votingOpen;
        return (
            <ContentWithToolbar id="results">
                <h1>Haunting on Texas Winners</h1>
                {!votingOpen && <>
                    <p>Click on each category to reveal the winners!</p>
                    {this.state.categories.map(category => {
                        let entryId = this.state.votes?.[category.id]?.[0] ?? "";
                        let winner = this.state.entries?.[entryId] ?? undefined;
                        return (
                            <div className="entry">
                                <h3 className={category.id} onClick={e => {
                                    e.target.classList.add("hide");
                                }}>{category.description}</h3>
                                {winner ? <>
                                    <img src={winner.selfie} />
                                    <b className={category.id}><span>{category.description}</span>{winner.description}</b>
                                </>
                                : <>
                                    <Icon icon={category.type === "PERSON" ? "user" : "cat"} />
                                </>}
                            </div>
                        );
                    })}
                </>}
                {votingOpen && <div className="message">
                    <p><b>Stay tuned...</b></p>
                    <p>Voting is still open! Check back later to see who wins</p>
                    <p>Need to vote or change your vote?</p>
                    <Button onClick={() => this.goTo("/Contest/Vote")}>Vote Here</Button>
                </div>}
            </ContentWithToolbar>
        );
    }
}

export default withNav(Results);
