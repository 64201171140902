export const waitFor = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const delayAction = async (action, timeout) => {
    try {
        await waitFor(timeout);
        action();
    } catch (e) {
        throw e;
    }
};
