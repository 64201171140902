import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMask, faSkull, faSkullCrossbones, faCrow, faBroom, faCat, faShareSquare,
		 faGhost, faSpider, faBookDead, faCloudMoon, faUser, faUserCircle, faBars,
		 faTimes, faImage, faCalendarAlt, faPlus, faHome, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './css/icon.css';

library.add(faMask, faSkull, faSkullCrossbones, faCrow, faBroom, faCat, faShareSquare,
			faGhost, faSpider, faBookDead, faCloudMoon, faUser, faUserCircle, faBars,
			faTimes, faImage, faCalendarAlt, faPlus, faHome, faArrowLeft, faArrowRight);

class Icon extends Component {
	render() {
		return <FontAwesomeIcon {...this.props} className={`icn ${this.props.className ?? ""}`} />;
	}
}

export default Icon;
