import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import { setThemeColor } from '../tools/colorScheme';

import Icon from '../components/Icon';

import './css/banner.css';

class Banner extends Component {
    state = {
        entry: {},
        category: "creative",
        categories: {
            "creative": "Most Creative Costume",
            "scary": "Scariest Costume",
            "sexy": "Sexiest Costume",
            "pet": "Best Pet Costume"
        }
    };

    getEntry = async entryId => {
        try {
            let doc = await Firestore.getDoc({ details: {
                collection: "entries",
                docId: entryId
            }});
            let entry = { id: doc.id, ...doc.data() };
            this.setState({ entry });
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let category = params.get('category');
        this.setState({ category });

        let entryId = this.props.match?.params?.entryId ?? false;
        if (entryId) {
            this.getEntry(entryId);
        } else {
            let description = params.get('costume') ?? "Me",
                selfie = params.get('image');
            let entry = {
                type: category === "pet" ? "PET" : "PERSON",
                description,
                selfie
            };
            this.setState({ entry });
        }
    }

    render () {
        let { entry } = this.state;
        return (
            <main id="bannerFrame">
                <div id="banner" className={this.state.category}>
                    <div id="content">
                        {entry?.selfie
                            ? <img src={entry.selfie} onLoad={() => {
                                let image = document.querySelector("#banner #content img");
                                image.dataset.loaded = "loaded";
                            }} />
                            : <Icon icon={entry?.type === "PET" ? "cat" : "user"} />
                        }
                        <div id="userInfo">
                            <h2><span>Vote for</span>{entry.description}</h2>
                            <div id="category">
                                {this.state.categories[this.state.category] ?? "Category"}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Banner;
