import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import { getRegisteredUser } from '../tools/userTracking';
import { withNav } from '../tools/withNav';

import ContentWithToolbar from '../templates/ContentWithToolbar';

import Icon from '../components/Icon';

import './css/sent.css';

class Sent extends Component {
    state = {
        userId: "",
        categories: {},
        votes: [],
        entries: {}
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = {};
            docs.forEach(doc => {
                categories[doc.id] = doc.data();
            });
            this.setState({ categories });
        });
    };

    getVotes = async userId => {
        try {
            let docs = await Firestore.getDocs("votes", {
                property: "userId", sign: "==", value: userId
            });
            let votes = [], requests = [];
            docs.forEach(doc => {
                let vote = doc.data();
                votes.push(vote);
                requests.push(Firestore.getDoc({ details: {
                    collection: "entries",
                    docId: vote.entryId
                }}));
            });
            let results = await Promise.all(requests);
            let entries = {};
            results.forEach(doc => {
                entries[doc.id] = doc.data();
            });
            this.setState({ votes, entries });
        } catch (e) {
            console.log(e);
        }
    };

    componentDidMount() {
        window.setTitle("Thanks for Voting! | Haunting on Texas");
        let userId = getRegisteredUser();
        this.getCategories();
        this.getVotes(userId);
    }

    render() {
        let { votes, categories, entries } = this.state;
        return (
            <ContentWithToolbar id="sent">
                <h2>Thanks for Voting</h2>
                <h3>Your Votes Are In!</h3>
                <section>
                    {votes.length > 0 && <>
                        {votes.sort((a, b) => {
                            let { entries } = this.state;
                            if (entries[a.entryId].type === entries[b.entryId].type) return 0;
                            return entries[a.entryId].type < entries[b.entryId].type ? -1 : 1;
                        }).map(vote => (
                            <div className="entry">
                                <img src={entries[vote.entryId].selfie} />
                                {categories[vote.categoryId]?.description &&
                                    <b className={vote.categoryId}>{categories[vote.categoryId].description}</b>
                                }
                            </div>
                        ))}
                    </>}
                    {votes.length === 0 && <>
                        <div className="entry load"><Icon icon="user" /></div>
                        <div className="entry load"><Icon icon="user" /></div>
                        <div className="entry load"><Icon icon="user" /></div>
                        <div className="entry load"><Icon icon="cat" /></div>
                    </>}
                </section>
                <div className="messageBox">You can change your votes anytime until the contest is over.</div>
                <p>Check the results page after the voting has closed<br />to find out who wins!</p>
                <div id="footer">
                    <button onClick={() => this.goTo("/Contest/Vote")}>Vote Again</button>
                    <button onClick={() => this.goTo("/Contest/Results")}>Check Results</button>
                </div>
            </ContentWithToolbar>
        );
    }
}

export default withNav(Sent);
