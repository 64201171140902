import React, { Component } from 'react';

import './css/button.css';

class Button extends Component {
    className = () => {
        let className = "btn";
        className += this.props.outline ? " outline" : "";
        return className;
    };

    render () {
        return (
            <button className={this.className()} {...this.props}>{this.props.children}</button>
        );
    }
}

export default Button;
