import React, { Component } from 'react';
import Firestore from '../tools/firestore_tools';
import { setThemeColor } from '../tools/colorScheme';
import { withNav } from '../tools/withNav';

import Icon from '../components/Icon';
import Button from '../components/Button';

import pumpkins from '../images/pumpkins.jpg';
import food from '../images/food.jpg';
import invite from '../invite.ics';

import './css/details.css';

class Details extends Component {
    state = {
        categories: []
    };

    getCategories = () => {
        Firestore.getDocs("categories").then(docs => {
            let categories = [];
            docs.forEach(doc => categories.push(doc.data()));
            this.setState({ categories: categories.sort((a, b) => {
                if (a.type === b.type) return 0;
                return a.type < b.type ? -1 : 1;
            }) });
        });
    };

    componentDidMount() {
        window.setTitle("You're Invited to a Haunting on Texas!");
        setThemeColor(24, 19, 26);
        this.getCategories();
    }

    render() {
        return (
            <main id="details">
                <section id="title">
                    <h1>The Haunting on<br />Texas Street</h1>
                    <h6>A J&J Event</h6>
                    <div className="location_time">
                        <div>
                            <a href={invite} download="halloween_party">
                                <Icon icon="calendar-alt" />
                                <Icon className="add" icon="plus" />
                            </a>
                            <b>October 29, 2021<br />@ 5:30 PM</b>
                        </div>
                        <span>4360 Texas St Unit 1<br />San Diego, CA 92104</span>
                    </div>
                    <p>We're throwing an epic Halloween party at our house! We'll have pumpkin carving, a costume contest, great food, and themed rooms to explore!</p>
                </section>
                <section id="pumpkins">
                    <img src={pumpkins} />
                    <div>
                        <h1>Pumpkin Carving</h1>
                        <p>We'll start the party off with the pumpkin carving!</p>
                        <p>If you want to participate, just bring a pumpkin. We'll have the tools ready for you and a candle.</p>
                    </div>
                </section>
                <section id="food">
                    <img src={food} />
                    <h1>Trick or Treat?</h1>
                    <p>Bring a Halloween snack to share and BYOB (Bring Your Own Brew). We'll have all the plates and utensils ready to go.</p>
                </section>
                <section id="contest" className="dark">
                    <h1>Costume Contest</h1>
                    <p>Come dressed up for one of three categories for our costume contest!</p>
                    <p>If you want enter your pet in the contest, there will even be a category for them too.</p>
                    <p>When you get to the party, find the QR code to take a selfie and enter to win!</p>
                    <p>Or click below to enter your costume.</p>
                    <Button onClick={() => this.goTo("/Contest")}>Enter Your Costume</Button>
                    <ul>
                        {this.state.categories.map(category => (
                            <li>
                                <img src={category.image} />
                                <b>{category.description}</b>
                            </li>
                        ))}
                    </ul>
                </section>
            </main>
        );
    }
}

export default withNav(Details);
