import React, { Component } from 'react';

import './css/contentWithToolbar.css';

class ContentWithToolbar extends Component {
    render () {
        return (
            <main id={this.props.id} className="contentWithToolbar">
                <div id="content">
                    {this.props.children}
                </div>
            </main>
        );
    }
}

export default ContentWithToolbar;
